.whatapp-section{
   position: fixed;
   right: 18px;
   bottom:43vh;
   z-index: 999;
   
}
.Whatapp-icon{
 width: 45px;
}
.whatapp-section1{
   position: fixed;
   right: 18px;
   bottom:33vh;
   z-index: 999;
 
   
}
.whatapp-section h6, .whatapp-section1 h6{
   color: #27CC64;
   font-size: 13px;
   font-family: 'Times New Roman', Times, serif;
}