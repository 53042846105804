.slider_bg1{
    background-image: url('https://res.cloudinary.com/dwunlkibs/image/upload/f_auto,q_auto/v1/FoodsPlantsOthers/dmrw5ow0805aqkmkboyo');
}
.slider_bg2{
    background-image: url('https://res.cloudinary.com/dwunlkibs/image/upload/f_auto,q_auto/v1/FoodsPlantsOthers/jdh7bxulo9fvlflqattv');
}
.slider_bg3{
    background-image: url('https://res.cloudinary.com/dwunlkibs/image/upload/f_auto,q_auto/v1/FoodsPlantsOthers/g32pcskfcklawbccr3h2');
}
.slider_bg1, .slider_bg2, .slider_bg3{
    background-size: 100% 100%;
    background-position: center center;
}
.slider img{
    width: 100%;
    height: 450px;
    background-size: cover;
}
.slider_content h6{
    padding-top: 9%;
 color: transparent;
}
.slider_content h1{
   line-height: 53px;
   color: transparent;
}
.slider_content a, .hero_main_content a{
    margin-top: 3%;
    padding: 8px 30px;
    text-transform: uppercase;
    border: 1px solid #67c177;
    border-radius: 50px;
    transition: 0.5s;
    font-weight: 900;
}
.slider_content a:hover, .hero_main_content a:hover{
    background-color: #67c177;
    color: white;
}
.swiper-custom-button-prev, .swiper-custom-button-next {
    position: absolute;
    top: 50%;
    margin-top: calc(0px - (var(--swiper-navigation-size)/ 2));
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 23px;
    color: rgba(255, 255, 255, 0.797);
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 10px 0 rgb(0 0 0 / 5%) inset;
    border-radius: 50%;
    width: 55px;
    height: 55px;
    transition: 0.5s;
    z-index: 999;
}
.swiper-custom-button-prev:hover, .swiper-custom-button-next:hover {
    background: #67c177;
    color: white;
}
.swiper-custom-button-prev {
    left: -80px;
    right: auto;
    transition: 0.7s;
}
.slider:hover .swiper-custom-button-prev{
    left: 10px;
    right: auto;
}
.swiper-custom-button-next{
    right: -80px;
    left: auto;
    transition: 0.7s;
}
.slider:hover .swiper-custom-button-next{
    right: 10px;
    left: auto;
}
.hero_main_content{
    position: relative;
    cursor: pointer;
    overflow: hidden;
    z-index: 1;
}
.hero_main_content img{
    transition: all 0.5s ease-out 0s;
}
.hero_main_content:hover img{
    transform: scale(1.1);
    overflow: hidden;
}
.overlay-img{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;
    width: 100%;
    height: 24%;
    transition: .5s ease;
}
.hero_main_content:hover .overlay-img {
    height: 50%;
  }
.hero_main_content p,h1{
    font-size: 20px;
    text-transform: capitalize;
}
/* **********Media-Screen********* */
 @media screen and (max-width:1024px) {
    .slider_bg1, .slider_bg2, .slider_bg3{
        height: 500px;
    } 
    .overlay-img{
        height: 20%;
    }
}
@media screen and (max-width:768px) {
    .slider_bg1, .slider_bg2, .slider_bg3{
        height: 400px;
    } 
  
} 
@media screen and (max-width:640px) {
    .overlay-img{
        height: 30%;
    }
    .hero_main_content:hover .overlay-img {
        height: 70%;
      }
} 
@media screen and (max-width:480px) {
    .hero_main_content a{
        font-size: 12px;
        padding: 4px 10px;
    }
    .overlay-img{
        height: 35%;
    }
    .hero_main_content:hover .overlay-img {
        height: 75%;
      }
      .hero_main_content p,h1{
        font-size: 16px;
        text-transform: capitalize;
    }
} 