.footer-section{
    padding-top: 60px;
    padding-bottom: 30px;
    background-image: url('https://res.cloudinary.com/dwunlkibs/image/upload/f_auto,q_auto/v1/FoodsPlantsOthers/ydiyooue5bsogvacdbwx');
    background-position: center;
    background-repeat: no-repeat ;
   background-size: cover;
}
.footer-top h3{
    font-size: 20px;
    color: #67c177;
    font-weight: 700;
    margin-bottom: 15px;
    text-transform: capitalize;
}
.footer-top p{
    color: white;
    line-height: 24px;
}
.contact-icon{
    background-color:white;
    color: #67c177;
   padding: 6px;
    font-size: 10;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 3%;
}
.footer-subs{
    background-color: #67c177;
    border-radius: 45px 5px 0px 0px;
    font-size: 18px;
    font-weight: 600;
}
.footer-subs:hover{
    background-color: #67c177;
}
.social{
    border-top: 1px solid rgba(255, 255, 255, 0.247);
    margin-top:30px ;
}
.footer-icon{
    color: white;
    border-radius: 50%;
	padding: 8px;
	font-size: 31px;
	margin: 0px 5px 0px 5px;
	transition: 0.5s;
}
.footer-icon:hover{
	transform: translatey(-5px);
	color: white;
}
.fa{
	background-color: #3b5998;
    box-shadow: #3b5998  0 -6px 8px inset;
}
.tw{
	background-color: #00acee;
    box-shadow: #00acee  0 -6px 8px inset;}
.yt{
	background-color: #FF0000;
    box-shadow: #FF0000  0 -6px 8px inset;

}
.li{
	background-color: #0077b5;
    box-shadow: #0077b5  0 -6px 8px inset;

}
.in{
	background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
    box-shadow: #feda75  0 -6px 8px inset;

}
.footer-divider {
    width: 40%;
    height: 1px;
    background-color: rgba(255, 255, 255, 0.247);
}


.scroll-to-top{
    display: flex;
    align-items: center;
    justify-content: center;
	background-color: white;
    right: 21px;
    bottom: 25px;
    position: fixed;
    z-index: 2;
    cursor: pointer;
    width: 40px!important;
    height: 40px!important;
	border-radius: 50% !important;
}